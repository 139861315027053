import { ADMIN_PERMISSIONS_NAME, DEFAULT_PERMISSIONS_NAME, PermissionsName } from './types';

export const APP_USER_CLIENT_TYPE = 'user-smood-business';
export const API_INGRESS_PATH = 'business';
export const APP_DISPLAY_NAME = 'Smood Business';
export const APP_SLUG = APP_DISPLAY_NAME.toLowerCase().replace(' ', '-');

export const DEFAULT_LOCATION_RADIUS = 0.2;

export const EMPLOYEE_INVITATION_TOKEN_PARAM_NAME = 'b2bInvitationToken';

export const FAVICON_DATA_URI =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAA7lJREFUaIHtmmtoW2Uch59zkjZJTbqUxrm1ldkp6JK6fpDhXaq7ZGH1Uim41lrpZN2nfRDBD4JsMqhYRBGFmV0cDrpKdQ4rrnWsUyc6B4LJWjoQtFovsNStJc096Xn90GVzNSd3PS3L8/Gc//vm+SX/856c5JWEEABI0itG2WTeIUm0Aw7gBhYXQWBMCHFECRvcQuyMAkhCCKSK3jpZ0n0mwVqNJbNCgFcRc80i9OLvEuw2yhXms0tFPokArxIqv1uWTeYdS00eQIJG2RTrli/3/JJEkmiXAbvWIgXQIANmrS0KwCxrbVAopQBaUwqgNaUAWlMKoDWlAFpz/QRocNjofNrxX7oA8GyHgwaHLet6faYCW7WJnj0P0tV5JwB/XQxzfPjn/A3T8OiWWzno3oyiCA4dHuOll7/m4qVw2jGSruJ1kfKEBB1tdnpfbeJGm+nKcf9sjKaNH3BudKqo8vY11ZweacO6zHDl2PRMhD09Z3hn7w8oSkpN9RbatOEWDu13XSMPUGkpZ/BoC/Y7qoukDg67jaHB1mvkAaqsRt7ofZiN61epjlUNcOrLSUbHUr/LdbUWvhrZistZn6fyVba4VnN6ZCu1Namfq7znfIx8Mak6XjVAPK7Q+dwQwWA85fkqq5HBo0+yf6+TlSty/wmpZqWZg+7NfPJRC8sqDSlrAoE4z2w7TiKhqM6jeg0kcTnrOTbwBHq9+oIViSQ43DdO/8B5vvn2D/V+lSUeuL+W9qfW0NFmx2hUX0PicYXHW49x4uQv6fQyB4D5i/nAu860IZL4Z2N4PD5+mphheiYCzH9at6220ti4nEpLecY5EgmFru3D9A+cz1ibVQCADY+sYuDIY1kJFEIgEKet81OGPp/Iqj7rG9nJU7/y0Pr+oi+f/8Tj9XFfU1/W8gA6uWzT7myLfVMh3nt/FEWBdXetoKxMl4/nvwiF4vS8dpau7iEuXAjlNDbrFlrITcsreOH5dWzfthaLOb+28s/GcB/w8uZb3+Obyk08Sd4BkphMelzOelpbbufee2q4uc6Stn7yNz9nvvuTDz/+keETE0QiiUJevvAACzGZ9FRZjRgMOgzl8y0Wjc0Rjc5xaTpSsPBCMn6Zy5VwOEE4HCj2tKpcP88Di5VSAK0pBdCaUgCtkYH/765TfPwyMK61RQGMy0KIPq0t8kUI0ScrYYNbgFdrmVwR4FHCwX2yEDujiphrXkohBHgUITcLsSsmXd1u87ZBNsW6L289aGDx/QEeAEbnt9sE9wmxKwbwN2z5QWk0m0rWAAAAAElFTkSuQmCC';

export const HEADER_NAMES = {
  authorization: 'Authorization',
  captchaToken: 'Captcha-Token',
  clientType: 'Client-Type',
  company: 'sm-b2b-company',
  referer: 'Referer',
} as const;

export const PERMISSIONS_RANKS = {
  [DEFAULT_PERMISSIONS_NAME]: 1,
  [ADMIN_PERMISSIONS_NAME]: Infinity,
} as const satisfies Record<PermissionsName, number>;
