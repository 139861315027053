export type Class<T extends object = object> = new () => T;

export type EmptyObject = { [k in never]: never };

export type FilteredObject<T extends object, TFilter> = { [P in keyof T as T[P] extends TFilter ? P : never]: T[P] };
export type FilteredObjectValue<T extends object, TFilter> = ObjectValue<FilteredObject<T, TFilter>>;

export type KeycloakPaths = Record<'certs' | 'logout' | 'token', string>;

export const LOG_LEVELS = { debug: 7, error: 3, info: 6, warning: 4 } as const;
export type LogLevel = keyof typeof LOG_LEVELS;

export type ObjectKey = number | string | symbol;
export type ObjectNestedProperty<T extends object, K extends ObjectKey> = ObjectRequiredValue<
  T,
  K
>[keyof ObjectRequiredValue<T, K> & K];
type ObjectRequiredValue<T extends object, K extends ObjectKey> = FilteredObjectValue<T, { [P in K]: unknown }>;
type ObjectValue<T extends object> = T[keyof T];

export const PLACE_PROVIDERS = ['google', 'pelias'] as const;
export type PlaceProvider = (typeof PLACE_PROVIDERS)[number];
export type Place = { id: string; provider: PlaceProvider; formatted: string };
export type PlaceDetails = Place & {
  country?: string;
  countryCode?: string;
  latitude: number;
  locality?: string;
  longitude: number;
  postalCode?: string;
  route?: string;
  streetNumber?: string;
};

export const LANGS = ['de', 'en', 'fr', 'it'] as const;
export type Lang = (typeof LANGS)[number];
export type LangParams = { lang: Lang };
export type InternationalizedPathname = { lang: Lang | null; pathname: string };

export type NonEmptyArray<T> = [T, ...T[]];

export type Translations = Record<Lang, string>;
