import { ReactNode } from 'react';

import { FeatureFlagName, PermissionsScope } from '@sbiz/business';
import { LangParams, ObjectNestedProperty } from '@sbiz/common';

export type MetadataGeneratorProps = { params: Promise<LangParams>; searchParams: Promise<Record<string, string>> };

export const ACCOUNTING_PAGE_NAME = 'accounting';
export const ADMIN_PAGE_NAME = 'admin';
export const AUTH_ERROR_PAGE_NAME = 'authError';
export const BENEFITS_PAGE_NAME = 'benefits';
export const CHECKS_PAGE_NAME = 'smoodChecks';
export const COMPANY_PAGE_NAME = 'company';
export const COMPANY_SELECTION_PAGE_NAME = 'companySelection';
export const EMAIL_UPDATE_KEYCLOAK_PAGE_NAME = 'emailUpdateKeycloak';
export const EMAIL_UPDATE_PAGE_NAME = 'emailUpdate';
export const EMAIL_UPDATE_PASSWORD_PAGE_NAME = 'emailUpdatePassword';
export const EMAIL_UPDATE_SUCCESS_PAGE_NAME = 'emailUpdateSuccess';
export const EMPLOYEES_PAGE_NAME = 'employees';
export const GIFT_CARDS_PAGE_NAME = 'giftCards';
export const HOME_PAGE_NAME = 'home';
export const LOGIN_KEYCLOAK_PAGE_NAME = 'loginKeycloak';
export const LOGIN_PAGE_NAME = 'login';
export const LOGIN_PASSWORD_PAGE_NAME = 'loginPassword';
export const LOGIN_SUCCESS_PAGE_NAME = 'loginSuccess';
export const LOGOUT_PAGE_NAME = 'logout';
export const LOGOUT_SUCCESS_PAGE_NAME = 'logoutSuccess';
export const MANAGERS_PAGE_NAME = 'managers';
export const NOT_FOUND_PAGE_NAME = 'notFound';
export const ONBOARDING_KEYCLOAK_PAGE_NAME = 'managerOnboardingKeycloak';
export const ONBOARDING_PAGE_NAME = 'managerOnboarding';
export const ONBOARDING_PASSWORD_PAGE_NAME = 'managerOnboardingPassword';
export const ONBOARDING_SUCCESS_PAGE_NAME = 'managerOnboardingSuccess';
export const ORDERS_PAGE_NAME = 'orders';
export const PASSWORD_FORGOT_PAGE_NAME = 'forgotPassword';
export const PASSWORD_RESET_PAGE_NAME = 'passwordReset';
export const PERMISSIONS_SETS_PAGE_NAME = 'permissionsSets';
export const PROFILE_PAGE_NAME = 'profile';
export const PROFILES_PAGE_NAME = 'profiles';
export const PURCHASES_PAGE_NAME = 'purchases';
export const REGISTER_PAGE_NAME = 'register';
export const SETTINGS_PAGE_NAME = 'settings';

export type PageName =
  | typeof ACCOUNTING_PAGE_NAME
  | typeof ADMIN_PAGE_NAME
  | typeof AUTH_ERROR_PAGE_NAME
  | typeof BENEFITS_PAGE_NAME
  | typeof CHECKS_PAGE_NAME
  | typeof COMPANY_PAGE_NAME
  | typeof COMPANY_SELECTION_PAGE_NAME
  | typeof EMAIL_UPDATE_KEYCLOAK_PAGE_NAME
  | typeof EMAIL_UPDATE_PAGE_NAME
  | typeof EMAIL_UPDATE_PASSWORD_PAGE_NAME
  | typeof EMAIL_UPDATE_SUCCESS_PAGE_NAME
  | typeof EMPLOYEES_PAGE_NAME
  | typeof GIFT_CARDS_PAGE_NAME
  | typeof HOME_PAGE_NAME
  | typeof LOGIN_KEYCLOAK_PAGE_NAME
  | typeof LOGIN_PAGE_NAME
  | typeof LOGIN_PASSWORD_PAGE_NAME
  | typeof LOGIN_SUCCESS_PAGE_NAME
  | typeof LOGOUT_PAGE_NAME
  | typeof LOGOUT_SUCCESS_PAGE_NAME
  | typeof MANAGERS_PAGE_NAME
  | typeof NOT_FOUND_PAGE_NAME
  | typeof ONBOARDING_KEYCLOAK_PAGE_NAME
  | typeof ONBOARDING_PAGE_NAME
  | typeof ONBOARDING_PASSWORD_PAGE_NAME
  | typeof ONBOARDING_SUCCESS_PAGE_NAME
  | typeof ORDERS_PAGE_NAME
  | typeof PASSWORD_FORGOT_PAGE_NAME
  | typeof PASSWORD_RESET_PAGE_NAME
  | typeof PERMISSIONS_SETS_PAGE_NAME
  | typeof PROFILE_PAGE_NAME
  | typeof PROFILES_PAGE_NAME
  | typeof PURCHASES_PAGE_NAME
  | typeof REGISTER_PAGE_NAME
  | typeof SETTINGS_PAGE_NAME;

export type Page = {
  featureFlag?: FeatureFlagName;
  isIndexed?: boolean;
  path: string;
  permissionsScopes?: PermissionsScope[];
};

export const PAGES = {
  [ACCOUNTING_PAGE_NAME]: { path: '/accounting', permissionsScopes: ['accounting'] },
  [ADMIN_PAGE_NAME]: { path: '/admin' },
  [AUTH_ERROR_PAGE_NAME]: { path: '/auth/error' },
  [BENEFITS_PAGE_NAME]: { path: '/benefits', permissionsScopes: ['benefits'] },
  [CHECKS_PAGE_NAME]: { path: '/smood-checks', permissionsScopes: ['smoodChecks'] },
  [COMPANY_PAGE_NAME]: { path: '/company' },
  [COMPANY_SELECTION_PAGE_NAME]: { path: '/company-selection' },
  [EMAIL_UPDATE_KEYCLOAK_PAGE_NAME]: { path: '/settings/email/update/keycloak' },
  [EMAIL_UPDATE_PAGE_NAME]: { path: '/settings/email/update' },
  [EMAIL_UPDATE_PASSWORD_PAGE_NAME]: { path: '/settings/email/update/password' },
  [EMAIL_UPDATE_SUCCESS_PAGE_NAME]: { path: '/settings/email/update/success' },
  [EMPLOYEES_PAGE_NAME]: { path: '/employees' },
  [GIFT_CARDS_PAGE_NAME]: { featureFlag: 'giftCardOrder', path: '/gift-cards', permissionsScopes: ['giftCards'] },
  [HOME_PAGE_NAME]: { path: '/' },
  [LOGIN_KEYCLOAK_PAGE_NAME]: { path: '/login/keycloak' },
  [LOGIN_PAGE_NAME]: { isIndexed: true, path: '/login' },
  [LOGIN_PASSWORD_PAGE_NAME]: { path: '/login/password' },
  [LOGIN_SUCCESS_PAGE_NAME]: { path: '/login/success' },
  [LOGOUT_PAGE_NAME]: { path: '/logout' },
  [LOGOUT_SUCCESS_PAGE_NAME]: { path: '/logout/success' },
  [MANAGERS_PAGE_NAME]: { path: '/managers' },
  [NOT_FOUND_PAGE_NAME]: { path: '/not-found' },
  [ONBOARDING_KEYCLOAK_PAGE_NAME]: { path: '/onboarding/keycloak' },
  [ONBOARDING_PAGE_NAME]: { path: '/onboarding' },
  [ONBOARDING_PASSWORD_PAGE_NAME]: { path: '/onboarding/password' },
  [ONBOARDING_SUCCESS_PAGE_NAME]: { path: '/onboarding/success' },
  [ORDERS_PAGE_NAME]: { path: '/orders', permissionsScopes: ['orders'] },
  [PASSWORD_FORGOT_PAGE_NAME]: { isIndexed: true, path: '/password/forgot' },
  [PASSWORD_RESET_PAGE_NAME]: { path: '/password/reset' },
  [PERMISSIONS_SETS_PAGE_NAME]: { path: '/admin/permissions/sets', permissionsScopes: ['adminPermissionsSets'] },
  [PROFILE_PAGE_NAME]: { path: '/profiles/[id]', permissionsScopes: ['profiles'] },
  [PROFILES_PAGE_NAME]: { path: '/profiles', permissionsScopes: ['profiles'] },
  [PURCHASES_PAGE_NAME]: { path: '/purchases', permissionsScopes: ['purchases'] },
  [REGISTER_PAGE_NAME]: { featureFlag: 'registration', isIndexed: true, path: '/register' },
  [SETTINGS_PAGE_NAME]: { path: '/settings' },
} as const satisfies Record<PageName, Page>;

export type PageLink = { icon: ReactNode; page: PageName };

export const PAGE_TABS = {
  [ACCOUNTING_PAGE_NAME]: [
    { key: 'invoices', permissionsScope: 'accounting' },
    { key: 'commercialGestures', permissionsScope: 'accounting' },
  ],
  [ADMIN_PAGE_NAME]: [
    { key: 'statements', permissionsScope: 'adminOpenInvoices' },
    { key: 'companies', permissionsScope: 'adminCompanies' },
    { key: 'orders', permissionsScope: 'adminOrders' },
    { key: 'giftCards', permissionsScope: 'adminGiftCardRequests' },
    { key: 'permissions', permissionsScope: 'adminPermissions' },
    { key: 'payments', permissionsScope: 'adminDatatrans' },
    { key: 'monthlyStatements', permissionsScope: 'adminOpenInvoices' },
    { key: 'commercialGestures', permissionsScope: 'adminCompensations' },
  ],
  [CHECKS_PAGE_NAME]: [
    { key: 'checks', permissionsScope: 'smoodChecks' },
    { key: 'distributions', permissionsScope: 'smoodChecks' },
  ],
  [COMPANY_PAGE_NAME]: [
    { key: 'information', permissionsScope: 'companyInformation' },
    { key: 'locations', permissionsScope: 'companyLocation' },
    { key: 'costCenters', permissionsScope: 'companyCostCenter', tourStepId: 'company_costCenters' },
    {
      featureFlag: 'creditCardManagement',
      key: 'creditCard',
      permissionsScope: 'companyCreditCard',
      tourStepId: 'company_creditCard',
    },
  ],
  [EMPLOYEES_PAGE_NAME]: [
    { key: 'employees', permissionsScope: 'employees' },
    { key: 'invitations', permissionsScope: 'employees' },
  ],
  [MANAGERS_PAGE_NAME]: [
    { key: 'managers', permissionsScope: 'managers' },
    { key: 'invitations', permissionsScope: 'managers' },
  ],
} as const satisfies { [k in PageName]?: PageTabDefinition[] };
export type PageTab = { component?: ReactNode } & PageTabDefinition;
export type PageTabDefinition = {
  actions?: ReactNode;
  featureFlag?: FeatureFlagName;
  key: string;
  label?: ReactNode;
  permissionsScope?: PermissionsScope;
  tourStepId?: TourStepId;
};
export type PageTabs = typeof PAGE_TABS;

export const PAGE_TOUR_ANCHORS = {
  [BENEFITS_PAGE_NAME]: { from: 'benefits', to: 'benefits' },
  [CHECKS_PAGE_NAME]: { from: 'checks', to: 'checks' },
  [COMPANY_PAGE_NAME]: { from: 'company', to: 'company_creditCard' },
  [EMPLOYEES_PAGE_NAME]: { from: 'employees', to: 'employees' },
  [GIFT_CARDS_PAGE_NAME]: { from: 'giftCards', to: 'giftCards' },
  [MANAGERS_PAGE_NAME]: { from: 'managers', to: 'managers' },
  [ORDERS_PAGE_NAME]: { from: 'orders', to: 'orders' },
  [PROFILES_PAGE_NAME]: { from: 'profiles', to: 'profiles' },
} as const satisfies { [k in PageName]?: { from: TourStepId; to?: TourStepId } };
export type PageTourAnchors = typeof PAGE_TOUR_ANCHORS;
export type PageTourAnchor = PageTourAnchors[keyof PageTourAnchors];

export const TOUR_STEPS = {
  benefits: { highlightId: 'left-nav-benefits', page: BENEFITS_PAGE_NAME },
  checks: { highlightId: 'left-nav-smoodChecks', page: CHECKS_PAGE_NAME },
  close: { highlightId: 'left-nav-help' },
  company_costCenters: { highlightId: 'company-tabs-costCenters', page: COMPANY_PAGE_NAME },
  company_creditCard: { highlightId: 'company-tabs-creditCard', page: COMPANY_PAGE_NAME },
  company: { highlightId: 'left-nav-company', page: COMPANY_PAGE_NAME },
  employees: { highlightId: 'left-nav-employees', page: EMPLOYEES_PAGE_NAME },
  giftCards: { highlightId: 'left-nav-giftCards', page: GIFT_CARDS_PAGE_NAME },
  managers: { highlightId: 'left-nav-managers', page: MANAGERS_PAGE_NAME },
  orders: { highlightId: 'left-nav-orders', page: ORDERS_PAGE_NAME },
  profiles: { highlightId: 'left-nav-profiles', page: PROFILES_PAGE_NAME },
  splashscreen: {},
} as const satisfies Record<string, { highlightId?: string; page?: PageName }>;
export type TourStep = { highlightId?: TourHighlightId; id: TourStepId; page?: PageName };
export type TourStepId = keyof typeof TOUR_STEPS;
export type TourHighlightId = ObjectNestedProperty<typeof TOUR_STEPS, 'highlightId'>;

const TOUR_STEP_SEQUENCE_IDS = [
  'splashscreen',
  'company',
  'company_costCenters',
  'company_creditCard',
  'profiles',
  'managers',
  'employees',
  'benefits',
  'checks',
  'giftCards',
  'orders',
  'close',
] as const satisfies TourStepId[];
export const TOUR_STEP_SEQUENCE = TOUR_STEP_SEQUENCE_IDS.map((id) => ({ id, ...TOUR_STEPS[id] }));
