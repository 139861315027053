import { EmptyObject, Lang, PlaceDetails, Translations } from '@sbiz/common';
import { DateRange } from '@sbiz/util-dates';
import { ObjectId, WithId } from '@sbiz/util-mongodb';

const INVOICES_ALERT_HEADER = 'Invoices';
const MONTHLY_STATEMENTS_ALERT_HEADER = 'Monthly statements';

export const API_ALERTS = {
  invoices_generation_empty: {
    header: INVOICES_ALERT_HEADER,
    message: 'There was no invoices to generate',
    severity: 'success',
  },
  invoices_generation_error: { header: INVOICES_ALERT_HEADER },
  invoices_generation_success: { header: INVOICES_ALERT_HEADER, severity: 'success' },
  invoices_notifications_creation_error: {
    header: INVOICES_ALERT_HEADER,
    message: 'Invoice notifications could not be created',
  },
  invoices_notifications_empty: {
    header: INVOICES_ALERT_HEADER,
    message: 'There was no invoice notifications to send',
    severity: 'success',
  },
  invoices_notifications_send_error: { header: INVOICES_ALERT_HEADER },
  invoices_notifications_success: { header: INVOICES_ALERT_HEADER, severity: 'success' },
  invoices_reminders_creation_error: {
    header: INVOICES_ALERT_HEADER,
    message: 'Invoice reminders could not be created',
  },
  invoices_reminders_empty: {
    header: INVOICES_ALERT_HEADER,
    message: 'There was no invoice reminders to send',
    severity: 'success',
  },
  invoices_reminders_send_error: { header: INVOICES_ALERT_HEADER },
  invoices_reminders_success: { header: INVOICES_ALERT_HEADER, severity: 'success' },
  monthly_statements_empty: {
    header: MONTHLY_STATEMENTS_ALERT_HEADER,
    message: 'There was no monthly statements to generate',
    severity: 'success',
  },
  monthly_statements_error: { header: MONTHLY_STATEMENTS_ALERT_HEADER },
  monthly_statements_success: { header: MONTHLY_STATEMENTS_ALERT_HEADER, severity: 'success' },
  receivable_discrepancy: {
    message: 'A discrepancy was found between the recorded and the computed receivable balances',
  },
  self_registration_email_error: {
    header: 'Self registration',
    message: 'The self registration email could not be sent',
  },
} as const satisfies Record<string, ApiAlert>;
export type ApiAlert = { header?: string; severity?: ApiAlertSeverity; message?: string };
export type ApiAlertCode =
  | keyof typeof API_ALERTS
  | `statements_${StatementNotificationType}_${StatementNotificationCode}`;
export type ApiAlertSeverity = 'error' | 'success' | 'warning';

export const API_EXCEPTIONS = {
  BENEFITS_CODE_ALREADY_IN_USE: 'Code already used in another voucher',
  BENEFITS_DISABLED: 'Benefits have been deactivated for the company',
  BENEFITS_NO_VALID_PROFILE: 'No valid profile was provided at the time of benefit creation',
  BENEFITS_NOT_FOUND: 'Missing benefit',
  BENEFITS_UNAUTHORIZED_COMPANY: 'The company in not authorized to use benefits',
  COMPANIES_COST_CENTERS_EMPTY_FILE: 'Could not parse the csv file',
  COMPANIES_FORBIDDEN: 'Trying to access a forbidden company',
  COMPANIES_NOT_FOUND: 'Missing company',
  COMPANIES_UID_ALREADY_IN_USE: 'A company with this uid is already registered',
  COMPANIES_UID_INVALID: 'Invalid company unique identifier',
  COMPENSATIONS_FORBIDDEN_DELETION: 'Compensations cannot be deleted',
  CRON_TOKEN_INVALID: 'Invalid cron token',
  CRON_TOKEN_MISSING: 'Missing cron token',
  CRON_TOKEN_MISSING_CONFIG: 'Missing cron token configuration',
  CUSTOMERS_NOT_FOUND_COMPANY: 'Missing company',
  CUSTOMERS_NOT_FOUND_EMPLOYEE: 'Missing employee',
  CUSTOMERS_NOT_FOUND_PROFILE: 'Missing profile',
  DATATRANS_CANCELATION_CONFLICT: 'The Datatrans transaction cannot be canceled',
  DATATRANS_SETTLEMENT_CONFLICT: 'The Datatrans transaction cannot be settled',
  EMPLOYEE_INVITATIONS_ALREADY_ENROLLED: 'This invitation has already been accepted',
  EMPLOYEE_INVITATIONS_CUSTOMER_ID_ALREADY_IN_USE: 'Customer id already attached to another employee',
  EMPLOYEE_INVITATIONS_EMPTY_FILE: 'Could not extract rows from the csv file',
  EMPLOYEE_INVITATIONS_NOT_FOUND: 'Missing employee invitation',
  EMPLOYEE_INVITATIONS_NOT_FOUND_COMPANY: 'Missing company',
  EMPLOYEE_INVITATIONS_NOT_FOUND_CUSTOMER: 'Missing customer',
  EMPLOYEE_INVITATIONS_NOT_FOUND_PROFILE: 'Missing profile',
  EMPLOYEE_INVITATIONS_TOO_EARLY: 'Too early to resend the invitation',
  EMPLOYEE_INVITATIONS_UNKNOWN: undefined,
  EMPLOYEES_NOT_FOUND: 'Missing employee',
  EMPLOYEES_NOT_FOUND_CUSTOMER: 'Missing customer',
  EMPLOYEES_NOT_FOUND_PROFILE: 'Missing profile',
  FEATURE_FLAGS_NOT_FOUND: 'Missing feature flag',
  INVOICES_GENERATION_FAILURE: 'Some invoices could not be generated',
  INVOICES_NOT_FOUND: 'Missing invoice',
  JWT_EXPIRED: 'Expired JSON Web token',
  JWT_MISSING: 'Missing JSON Web Token',
  JWT_INVALID: 'Invalid JSON Web Token',
  KEYCLOAK_MISSING_PERMISSION: 'Missing permission',
  KEYCLOAK_MISSING_PUBLIC_KEY: 'Missing public key',
  KEYCLOAK_MISSING_TOKEN: 'Missing token',
  LOGIN_INVALID_CREDENTIALS: 'Invalid email or password',
  MANAGER_INVITATIONS_ALREADY_ACCEPTED: 'This invitation has already been accepted',
  MANAGER_INVITATIONS_EMAIL_MISMATCH: 'The wrong username was used to accept the invitation',
  MANAGER_INVITATIONS_NOT_FOUND: 'Missing manager invitation',
  MANAGER_INVITATIONS_NOT_FOUND_COMPANY: 'Missing company',
  MANAGER_INVITATIONS_TOO_EARLY: 'Too early to resend the invitation',
  MANAGERS_EMAIL_ALREADY_IN_USE: 'This email address is already in use',
  MANAGERS_EMAIL_MISMATCH: 'The wrong username was used to update the email address',
  MANAGERS_EMAIL_UNCHANGED: 'This email address is the same as the current one',
  MANAGERS_NOT_FOUND: 'Missing manager',
  ORDERS_NOT_FOUND_COST_CENTER: 'Missing cost center',
  PERMISSIONS_FORBIDDEN_NAME_EDIT: 'Managers are not allowed to edit admin permissions',
  PERMISSIONS_FORBIDDEN_SCOPE_EDIT: 'The permissions scope is not allowed to be edited',
  PERMISSIONS_FORBIDDEN_SELF_EDIT: 'Managers are not allowed to edit their own permissions',
  PERMISSIONS_NOT_FOUND: 'Missing permissions',
  PERMISSIONS_SETS_NOT_FOUND: 'Missing permissions set',
  PROFILES_MISSING_DEFAULT: 'The default profile is not set for this company',
  PROFILES_NAME_ALREADY_IN_USE: 'Profile name already in use',
  STATEMENTS_GENERATION_CONFLICT: 'A statement was already generated',
  STATEMENTS_GENERATION_FAILURE: 'Some statements could not be generated',
  STATEMENTS_INVALID_DATE_RANGE: 'Invalid date range',
  STATEMENTS_NOT_FOUND_COMPANY: 'Missing company',
  STATEMENTS_NOT_FOUND: 'Missing statement',
  STATEMENTS_REGENERATION_FORBIDDEN: 'The statement cannot be regenerated',
  VALIDATION_PIPE_INVALID: 'Validation failed',
} as const;
export type ApiExceptionCode = keyof typeof API_EXCEPTIONS;
export const API_EXCEPTIONS_CODES = Object.keys(API_EXCEPTIONS) as ApiExceptionCode[];

export type AppTourSeenStep = { id: string; seenAt: Date };
export type AppTour = { closedAt?: Date; dismissedAt?: Date; seenSteps: AppTourSeenStep[] };

export type CompanyCostCenters = { filename?: string; filepath?: string; list: string[] };
export type CompanyFee = { isCharged: boolean; percentage?: number };
export type CompanyFees = { service?: CompanyFee };
export type CompanyFeesSetting = { service: { percentage: number } };
export type CompanyLocation = { address: PlaceDetails; name: string; fullAddress: string; radius: number };
export type CompanyRebate = { isActive: boolean; items?: CompanyRebateItem[] };
export type CompanyRebateItem = { threshold: number; value: { percentage: number } };
export type CompanySetting = { fees: CompanyFeesSetting; rebate: CompanyRebateItem[] };

export const STATEMENT_FORMATS = ['default', 'detailed'] as const;
export type StatementFormat = (typeof STATEMENT_FORMATS)[number];

export const STATEMENT_THEMES = ['bnw', 'color'] as const;
export type StatementTheme = (typeof STATEMENT_THEMES)[number];

export type BaseCompany = {
  companyRegister?: string;
  contactEmail: string;
  contactEmailFinance?: string[];
  externalReference?: string;
  fees?: CompanyFees;
  invoiceAddress?: string;
  invoiceColorTheme?: StatementTheme;
  invoiceContactName?: string;
  invoiceFormat?: StatementFormat;
  invoiceRef: string;
  lang?: Lang;
  name: string;
  paymentTerms: number;
  postalAddress: string;
  purchaseOrderNumber?: string;
  rebate?: CompanyRebate;
  shouldEmailForNewInvoice?: boolean;
  vatNumber?: string;
};

export const CHARGEABLE_AMOUNT_KEYS = ['amount', 'amountExclTax', 'normalVat', 'reducedVat'] as const;
export type ChargeableAmountKey = (typeof CHARGEABLE_AMOUNT_KEYS)[number];
export type Chargeable<T extends object = EmptyObject> = Record<ChargeableAmountKey, number> & T;
export type ChargeableResource<T extends object = EmptyObject> = Resource<Chargeable<T>>;

export type Company = {
  benefitsActivated: boolean;
  contactPhoneNumber: string;
  costCenters?: CompanyCostCenters;
  createdAt: Date;
  deliveryAddress?: string;
  dropOffAreas?: CompanyLocation[];
  isReceivableLimitSet?: boolean;
  portalAdminContact?: ObjectId;
  prefix?: string;
  receivableLimit: number;
  salesRepresentativeEmail?: string;
  updatedAt: Date;
  website?: string;
} & BaseCompany;
export type CompanyResource = DeletableResource<Company>;

export const BENEFIT_REDUCTION_TYPES = ['fixedAmount', 'freeDelivery'] as const;
export type BenefitReductionType = (typeof BENEFIT_REDUCTION_TYPES)[number];

export type BenefitRestrictedZone = {
  formatted: string;
  location: { latitude: number; longitude: number };
  name: string;
  radius: number;
};

export type BenefitSchedule = { maxUse?: number; weekdays: string; from: string; to: string };

export type CheckDistributionEmployee = WithId<{
  amount: number;
  customerId: string;
  email: string;
  endDate?: Date;
  firstname: string;
  isSuccess: boolean;
  lastname: string;
  startDate?: Date;
}>;

export type Compensation = Taxable<Chargeable<{ company: ObjectId; internalNote: string; type: CompensationType }>>;
export type CompensationResource = DeletableResource<Compensation>;
export const COMPENSATION_TYPES = ['commercialGesture'] as const;
export type CompensationType = (typeof COMPENSATION_TYPES)[number];

export type Customer = { _id: string; email: string; b2b?: CustomerBusinessDetails };
export type CustomerBusinessDetails = { company: string; profile: string };

export const DURATION_UNITS = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'] as const;
export type DurationUnit = (typeof DURATION_UNITS)[number];

export type FeatureFlag = { isEnabled: boolean; name: FeatureFlagName };
export type FeatureFlagName =
  | 'creditCardManagement'
  | 'giftCardOrder'
  | 'invoices'
  | 'keycloakAuthentication'
  | 'registration';
export type FeatureFlags = { [k in FeatureFlagName]?: boolean };

export const FILE_EXPORT_EXTENSIONS = ['csv', 'pdf', 'xlsx'] as const;
export type FileExportExtension = (typeof FILE_EXPORT_EXTENSIONS)[number];

export type InvitationInterval = { amount: number; unit: DurationUnit };

export type InvoiceCompany = WithId<BaseCompany>;
export type Invoice = {
  company: InvoiceCompany;
  dueAt: Date;
  externalId: string;
  filePath?: string;
  issuedAt: Date;
  notification?: InvoiceNotification;
  number: string;
  paidAt?: Date;
  provider: InvoiceProvider;
  reminders?: InvoiceNotification[];
  replacedInvoice?: ObjectId;
};
export type InvoiceResource = Resource<Invoice>;
export type PopulatedInvoice = { statement: StatementResource } & Resource<Invoice>;
export const INVOICE_PROVIDERS = ['api-accounting', 'api-invoices'] as const;
export type InvoiceNotification = {
  batchId: string;
  emails: string[];
  processedAt: Date;
  sentAt: Date;
  templateId: string;
};
export type InvoiceProvider = (typeof INVOICE_PROVIDERS)[number];

export type Manager = {
  acceptedTermsOfUseDate?: Date;
  acceptedTermsOfUseVersion?: string;
  appTour: AppTour;
  email: string;
  firstname: string;
  isTester?: boolean;
  keycloakId?: string;
  lang?: Lang;
  lastname: string;
  password?: string;
  resetPasswordTokenValidity?: Date;
  userId?: string;
};
export type ManagerListItem = {
  company: Pick<CompanyResource, '_id' | 'name'>;
  permissions: Omit<PermissionsResource, 'values'>;
} & Omit<ManagerResource, 'permissions'>;
export type ManagerResource = Resource<Manager>;

export type ManagerEmailUpdateInfo = { isKeycloakRegistration: boolean };

export type ManagerStatus = { companyName: string; lang?: Lang; permissionsName: PermissionsName };
export type ManagerInvitationInfo = {
  email: string;
  isKeycloakRegistration: boolean;
  isRegistration: boolean;
  pending: ManagerStatus;
};

export type ManagerInvitationTokenPayload = { company: string } & ManagerActionTokenPayload;

export type ManagerAction = 'email-update' | 'manager-onboarding';

export type ManagerActionTokenPayload = {
  action: ManagerAction;
  email: string;
  exp?: number;
  firstname: string;
  lang: Lang;
  lastname: string;
  sub: string;
};

export type Offer = DeletableResource<{
  company: ObjectId;
  endDate: Date;
  startDate: Date;
  target: OfferTarget;
  value: OfferValue;
}>;
export type OfferValue = { percentage: number };
export const OFFER_TARGETS = ['fees.service'] as const;
export type OfferTarget = (typeof OFFER_TARGETS)[number];

export const ENDED_ORDER_STATUSES = ['complete', 'canceled'] as const;
export type EndedOrderStatus = (typeof ENDED_ORDER_STATUSES)[number];
export const ORDER_SUMMARY_STATUSES = [...ENDED_ORDER_STATUSES, 'pending'] as const;
export type OrderSummaryStatus = (typeof ORDER_SUMMARY_STATUSES)[number];

export type OrderBenefit = {
  amount: number;
  code: string;
  isFreeDelivery?: boolean;
};

export type OrderSummaryBenefit = OrderBenefit & { isFreeDelivery: boolean };

export type OrderSummary = {
  benefits: OrderSummaryBenefit[];
  companyId: string;
  number: string;
  status: OrderSummaryStatus;
};
export type EndedOrderSummary = OrderSummary & { status: EndedOrderStatus };

export type OrderAddress = {
  country?: string;
  formatted: string;
  latitude: number;
  locality?: string;
  longitude: number;
  note?: string;
  postalCode?: string;
  route?: string;
  streetNumber?: string;
};

export type OrderProductChoice = {
  name: Translations;
  quantity: number;
  refChoice: string;
};

export const ORDER_PRODUCT_STATES = [
  'adjusted',
  'cancelled',
  'collected',
  'new',
  'outOfStock',
  'replaced',
  'validated',
  'validationRequired',
] as const;
export type OrderProductState = (typeof ORDER_PRODUCT_STATES)[number];
export type OrderProduct = {
  id: string;
  categoryName?: Translations;
  combinationChoices?: OrderProductChoice[];
  description?: string;
  name: Translations;
  quantity: number;
  state: OrderProductState;
  totalPrice: number;
};

export const CUSTOMER_ORDER_STATUSES = [
  'complete',
  'consulting',
  'delivering',
  'making',
  'new',
  'picked',
  'ready',
  'waiting_customer',
] as const;

export const ORDER_STATUSES = [
  ...CUSTOMER_ORDER_STATUSES,
  'cancel_requested',
  'canceled',
  'canceled_reimbursed',
  'canceled_treated',
  'error_payment',
  'new_flagged',
  'new_pending',
  'pending_driver',
  'pending_manufacturer',
  'waiting_payment',
] as const;
export type OrderStatus = (typeof ORDER_STATUSES)[number];

export type OrderStore = {
  id: string;
  url: string;
};

export type OrderTips = { driver?: { amount: number } };

export type Order = {
  _id: string;
  b2b: { company: string; companyName: string; profile: string; profileName: string };
  benefits: OrderBenefit[];
  canceledAt?: string;
  completedAt?: string;
  completedByRestaurantAt?: string;
  costCenter?: string;
  createdAt: string;
  customer: string;
  customerInvoicingData?: OrderInvoiceData;
  customerName: string;
  deliveredAt?: string;
  dropoffAddress: OrderAddress;
  dropoffDeadline: string;
  dropoffDeadlineMax: string;
  dropoffEta?: string | null;
  fromAccount: number;
  fromCheckAccount: number;
  number: string;
  pickupAddress: OrderAddress;
  restaurantName: string;
  serviceFee: number;
  status: OrderStatus;
  tips?: OrderTips;
  totalDiscountsRestaurant: number;
  totalDiscountsSmood: number;
  totalOrder: number;
  totalOrderWithTips: number;
  totalPaid: number;
  totalProducts: number;
  totalShipping: number;
  vouchersDetails: { code: string; id: string }[];
};

export type OrderInvoiceData = {
  products: {
    name: string;
    quantity: number;
    state: string;
    tax: number;
    totalPriceHT: number;
    totalPriceTTC: number;
    unitPriceHT: number;
    vatReduced: number;
    vatStandard: number;
  }[];
  totals: { total: { HT: number; TTC: number; vatReduced: number; vatStandard: number } };
  vatValues: { reduced: number; standard: number };
};

export const PAYMENT_PROVIDERS = ['datatrans'] as const;
export type PaymentProvider = (typeof PAYMENT_PROVIDERS)[number];

export const PERMISSIONS_ACTIONS = ['read', 'write'] as const;
export type PermissionsAction = (typeof PERMISSIONS_ACTIONS)[number];

export const ADMIN_PERMISSIONS_NAME = 'SmoodAdmin';
export const DEFAULT_PERMISSIONS_NAME = 'PortalManager';
export const PERMISSIONS_NAMES = [DEFAULT_PERMISSIONS_NAME, ADMIN_PERMISSIONS_NAME] as const;
export type PermissionsName = (typeof PERMISSIONS_NAMES)[number];

export const PERMISSIONS_SCOPES = [
  'accounting',
  'adminCompanies',
  'adminCompensations',
  'adminDatatrans',
  'adminEmployees',
  'adminGiftCardRequests',
  'adminOpenInvoices',
  'adminOrders',
  'adminPermissions',
  'adminPermissionsSets',
  'apiUrl',
  'benefits',
  'companyAllowableExpense',
  'companyCostCenter',
  'companyCreditCard',
  'companyInformation',
  'companyInvoiceSetting',
  'companyLocation',
  'employees',
  'giftCards',
  'managers',
  'orders',
  'permissions',
  'profiles',
  'purchases',
  'smoodChecks',
] as const;
export type Permissions = {
  company: ObjectId;
  manager: ObjectId;
  name: PermissionsName;
  values: PermissionsValues;
};
export type PermissionsResource = DeletableResource<Permissions>;
export type PermissionsActions = { [k in PermissionsAction]?: boolean };
export type PermissionsScope = (typeof PERMISSIONS_SCOPES)[number];
export type PermissionsSet = Pick<Permissions, 'name' | 'values'>;
export type PermissionsSetResource = Resource<PermissionsSet>;
export type PermissionsValues = { [k in PermissionsScope]?: PermissionsActions };

export type Resource<T extends object = EmptyObject> = { _id: ObjectId; createdAt: Date; updatedAt: Date } & T;
export type DeletableResource<T extends object = EmptyObject> = Resource<{ deletedAt?: Date | null } & T>;

export type StatementCompensation = Taxable<Chargeable<{ details: StatementCompensationDetails }>>;
export type StatementCompensationDetails = Pick<CompensationResource, '_id' | 'createdAt' | 'internalNote' | 'type'>;
export type StatementCompensations = Chargeable<{ items: StatementCompensation[] }>;

export const STATEMENT_EXPORT_EXTENSIONS = ['csv', 'xlsx'] as const;
export type StatementExportExtension = (typeof STATEMENT_EXPORT_EXTENSIONS)[number];

export type CheckPurchaseDetails = WithId<{
  check: CheckPurchaseSummary;
  createdAt: Date;
  employees: CheckPurchaseEmployee[];
  profile: CheckPurchaseProfile;
}>;
export type CheckPurchaseEmployee = { originalAmount: number } & CheckDistributionEmployee;
export type CheckPurchaseProfile = WithId<{ name: string }>;
export type CheckPurchaseSummary = WithId<{ amount: number; dayOfDistribution: number; name: string }>;
export type GiftCardPurchaseDetails = WithId<{
  amount: number;
  issuedAt: Date;
  quantity: number;
  nameForClient: string;
}>;
export type OrderPurchaseDetails = Taxable<
  Chargeable<{ benefits: StatementOrderBenefit[]; completedAt: Date; products: StatementOrderProduct[] }> &
    Pick<Order, 'costCenter' | 'dropoffAddress' | 'customerName' | 'restaurantName' | 'number'>
>;
export type StatementOrderBenefit = Chargeable<OrderBenefit>;
export type StatementOrdersBenefit = { code: string; total: number; totalExclTax: number };
export type StatementPayment = { amount: number; card?: StatementPaymentCard; createdAt: Date };
export type StatementPaymentCard = { brand: string; masked: string };
export type StatementOrderProduct = Chargeable<{
  description: string;
  quantity: number;
  state: string;
  unitPriceExclTax: number;
  vatRate: number;
}>;

export const STATEMENT_PURCHASE_AMOUNT_KEYS = [...CHARGEABLE_AMOUNT_KEYS, 'paidAmount', 'remainder'] as const;
export type StatementPurchaseAmountKey = (typeof STATEMENT_PURCHASE_AMOUNT_KEYS)[number];
export type StatementPurchaseAmounts = Record<StatementPurchaseAmountKey, number>;
export type StatementPurchase<T = unknown> = Taxable<
  StatementPurchaseAmounts & { details: T; payments: StatementPayment[] }
>;
export type StatementPurchases<T = unknown> = (T extends OrderPurchaseDetails
  ? { benefits: StatementOrdersBenefit[] }
  : EmptyObject) &
  StatementPurchaseAmounts & { items: StatementPurchase<T>[] };

export const STATEMENT_PURCHASE_TYPES = ['check', 'giftCard', 'order'] as const;
export type StatementPurchaseType = (typeof STATEMENT_PURCHASE_TYPES)[number];

export type StatementRebate = Chargeable<{ isActive: boolean; items: StatementRebateItem[]; originalAmount: number }>;
export type StatementRebateItem = Chargeable<{
  eligibleAmount: number;
  eligibleAmountExclTax: number;
  purchases: StatementRebatePurchase[];
}> &
  CompanyRebateItem;
export type StatementRebatePurchase = Chargeable<{
  date: Date;
  originalAmount: number;
  type: StatementPurchaseType;
  ref: string;
}>;

export type StatementServiceFee = Chargeable<{
  isCharged: boolean;
  offer?: OfferValue;
  originalAmount: number;
  originalAmountExclTax: number;
  originalNormalVat: number;
  originalReducedVat: number;
  percentage: number;
}>;
export type Statement = {
  company: InvoiceCompany;
  checkDistributions: StatementPurchases<CheckPurchaseDetails>;
  compensations: StatementCompensations;
  exports?: string[];
  giftCards: StatementPurchases<GiftCardPurchaseDetails>;
  invoice?: ObjectId;
  orders: StatementPurchases<OrderPurchaseDetails>;
  paidAmount: number;
  payments: StatementPayment[];
  period: DateRange;
  rebate: StatementRebate;
  remainder: number;
  serviceFee: StatementServiceFee;
  setting: CompanySetting;
  subtotal: number;
  subtotalExclTax: number;
  subtotalNormalVat: number;
  subtotalReducedVat: number;
  total: number;
  totalExclTax: number;
  totalNormalVat: number;
  totalReducedVat: number;
};
export type StatementResource = Resource<Statement>;

export type StatementNotificationCode = 'cancellation' | 'creation' | 'empty' | 'historyCreation' | 'send' | 'success';
export type StatementNotificationType = 'notifications' | 'reminders';

export type Taxable<T extends object = EmptyObject> = { normalVatRate: number; reducedVatRate: number } & T;
export type TaxableResource<T extends object = EmptyObject> = Resource<Taxable<T>>;

export const TRANSACTION_TYPES = [
  'BenefitTransaction',
  'CheckTransaction',
  'CompensationTransaction',
  'GiftCardTransaction',
  'InvoiceTransaction',
  'PaymentTransaction',
] as const satisfies TransactionType[];

export type TransactionDetails = {
  BenefitTransaction: { adjustmentId?: ObjectId; benefits: TransactionOrderBenefit[]; orderNumber: string };
  CheckTransaction: { distributionId: ObjectId };
  CompensationTransaction: { compensationId: ObjectId } & Pick<Compensation, 'amount' | 'type'>;
  GiftCardTransaction: { amount: number; name: string; quantity: number; giftCardId: ObjectId };
  InvoiceTransaction: { invoiceAmount: number; invoiceId: ObjectId };
  PaymentTransaction: {
    benefits?: TransactionOrderBenefit[];
    checkDistributionId?: ObjectId;
    checkId?: ObjectId;
    giftCardId?: ObjectId;
    orderNumber?: string;
    paymentId: string;
    paymentProvider: PaymentProvider;
  };
};
export type TransactionType = keyof TransactionDetails;

export type TransactionOrderBenefit = { amount: number; code: string; isFreeDelivery: boolean };

export type Transaction<T extends TransactionType> = {
  amount: number;
  details: TransactionDetails[T];
  company: ObjectId;
  type: T;
};
export type TransactionResource<T extends TransactionType> = Resource<Transaction<T>>;

export type UserCompany = { _id: ObjectId; name: string };
export type UserInfo = {
  _id: ObjectId;
  companies: UserCompany[];
  email: string;
  firstname: string;
  lastname: string;
  permissions: PermissionsValues;
  permissionsAdminScopes?: PermissionsScope[];
  permissionsName: PermissionsName;
  refreshRequiredAt: Date;
};
